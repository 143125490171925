import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ProdukHukumTemplate = ({
  helmet,
  title,
  link,
  tahun,
  pdf,
  categories = [],
}) => {

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <h1 className="title is-size-2 has-text-weight-bold is-bold-light" style={{ textAlign: 'center' }}>
             Produk Hukum: {title}
        </h1>
        <div style={{ maxWidth: '500px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
          <table>
            <tbody>
              <tr>
                <td>Nama Peraturan</td>
                <td>{title}</td>
              </tr>
              <tr>
                <td>Tahun</td>
                <td>{tahun}</td>
              </tr>
              <tr>
                <td>Kategori</td>
                <td>{categories && categories.map(cat => cat.category).join(', ')}</td>
              </tr>
            </tbody>
          </table>
          <br />
            <Link to="/crafts"><button>Kembali</button></Link>
            {pdf &&
              <a href={pdf} target="_blank"> <button /> </a>
            }
            {link &&
              <a href={link} target="_blank"> <button>Unduh</button> </a>
            }
            
        </div>
      </div>
    </section>
  )
}

ProdukHukumTemplate.propTypes = {
  title: PropTypes.string,
  pdf: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  tahun: PropTypes.string,
  featuredpost: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.string,
}

const ProdukHukum = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ProdukHukumTemplate
        helmet={
          <Helmet titleTemplate="%s | Hukum">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.title}`}
            />
          </Helmet>
        }
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        pdf={post.frontmatter.pdf}
        tahun={post.frontmatter.tahun}
        link={post.frontmatter.link}
        categories={post.frontmatter.categories}
      />
    </Layout>
  )
}


ProdukHukum.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProdukHukum

export const hukumPageQuery = graphql`
  query ProdukHukumByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tahun
        featuredpost
        link
        pdf
        categories {
              category
            }
      }
    }
  }
`